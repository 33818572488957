@import "../../../src/styles/_constants.scss";

@mixin styling($responsive, $responsiveMenu) {
  .transaction-commissions {
    .text-small {
      font-size: 0.8rem;
    }

    .user-box, .cuit-box, .cvu-box {
      font-family: "Nexa Bold", sans-serif;
      font-size: calc(27px * #{$responsive});
      color: $text_color_dark;
      /*margin-top: calc(28px * #{$responsive});*/
      display: flex;
      align-items: center;

      .users, .cuits, .cvus {
        width: calc(716px * #{$responsive});
        margin-left: calc(29px * #{$responsive});
        display: flex;
        align-items: center;

        input {
          /*width: calc(184px * #{$responsive});*/
          box-shadow: 0 calc(4px * #{$responsive}) calc(3px * #{$responsive}) rgba(0, 0, 0, 0.25);
        }
      }

      button {
        width: calc(144px * #{$responsive});
        height: calc(45 * #{$responsive});
        font-size: calc(16px * #{$responsive});
        font-family: Gotham, sans-serif;
      }
    }
  }
}

@import "../../../src/styles/_responsive.scss";